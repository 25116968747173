<template>
  <div>
    <el-form ref="queryForm" :inline="true" :model="queryForm" size="small">
      <el-form-item label="关键字" prop="key">
        <el-input v-model="queryForm.key" placeholder="名称/车牌"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="queryForm.status" placeholder="请选择">
          <el-option label="启用" :value="1"></el-option>
          <el-option label="禁用" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleReset" type="infor">重置</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" :header-cell-style="{background:'#E4E7ED',color: '#606266'}"
      border stripe size="small" style="width: 100%;" max-height="500px">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="车牌" prop="car_number"></el-table-column>
      <el-table-column label="状态">
        <template v-slot="{ row }">
          <el-tag v-if="row.status === 1" type="success" size="small">启用</el-tag>
          <el-tag v-else type="info" size="small">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="禁用原因" prop="reason"></el-table-column>
      <el-table-column label="操作" v-slot="{ row }">
        <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getVehicleData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="closeDialog"
      width="30%">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="车辆名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="车牌号" prop="car_number">
          <el-input v-model="form.car_number"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.status == 0" label="禁用原因" prop="reason">
          <el-input v-model="form.reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    saveVehicleAPI,
    getVehicleDataAPI,
  } from '@/api/dispatch-vehicle.js'

  export default {
    name: 'VehicleManagement',
    data() {
      return {
        queryForm: {
          page: 1,
          status: '',
          key: '',
        },
        tableData: [],
        total: 0,
        pageSize: 0,
        tableLoading: false,
        curRowData: null,

        // 表单相关
        dialogTitle: '',
        showDialog: false,
        submitting: false,
        form: {
          name: '',
          car_number: '',
          status: 1,
          reason: ''
        },
        rules: {
          name: [{
            required: true,
            message: '车辆名称不能为空',
            trigger: 'blur'
          }],
          car_number: [{
            required: true,
            message: '车牌号称不能为空',
            trigger: 'blur'
          }],
        }
      }
    },
    created() {
      this.getVehicleData()
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      // 查询按钮
      handleSearch() {
        this.queryForm.page = 1
        this.getVehicleData()
      },
      // 重置按钮
      handleReset() {
        this.$refs.queryForm.resetFields()
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 编辑按钮
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))
        // 设置 form
        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
      },
      // 提交按钮
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return
          // 如果是启用的，将禁用原因置空
          if (this.form.status === 1) this.form.reason = ''

          this.submitting = true
          const copyForm = JSON.parse(JSON.stringify(this.form))

          if (this.dialogTitle === '编辑') copyForm.id = this.curRowData.id
          // 提交
          saveVehicleAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getVehicleData()
          }).finally(() => this.submitting = false)
        })
      },
      // 获取车辆数据
      getVehicleData() {
        this.tableLoading = true

        getVehicleDataAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      }
    }
  }
</script>

<style scoped>
</style>